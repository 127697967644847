import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row, config } from "react-awesome-styled-grid"
import Layout from "../../components/Layout"

import { Hero, HeroContent, HeroHeading } from "../../components/Hero"
import BackgroundImage from "gatsby-background-image"
import DivHelper from "../../components/DivHelper"
import { HoverButton, TrackedLink } from "../../components/Button"
import styled from "styled-components"

import { Heading2 } from "../../components/Heading"

import { FaStar } from "@react-icons/all-files/fa/FaStar"
import { FaStarHalfAlt } from "@react-icons/all-files/fa/FaStarHalfAlt"

import { ArrowButton } from "../../components/Button"

import { Collapse } from "react-collapse"

import logo from "../../assets/images/logo/logo-blue.svg"
import { TableOfContents } from "../../components/TableOfContents"
import Block from "../../components/Block"
import { IconCheck, IconCross } from "../../components/Icons"

const NoRecommendation = styled.span`
  color: #db3b3b;
  font-weight: 600;
  text-align: center;
`

const TableWrapper = styled.div`
  overflow-x: auto;
`

const Table = styled.table`
  user-select: none;
  font-size: 0.9rem;
  border: none;
  outline: none;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0 auto;
  width: 1190px;

  th,
  td {
    padding: 15px 10px;
    background-color: #fff;
  }

  th {
    hyphens: auto;
    text-align: left;
    width: 100px;
    position: sticky;
    left: 0px;
  }

  tr:nth-child(even) > th,
  tr:nth-child(even) > td {
    background-color: #d9ebfc;
  }

  td {
    width: auto;
  }

  @media (min-width: 576px) {
    th {
      width: 150px;
    }
  }

  @media (min-width: 768px) {
    th {
      width: 175px;
    }
  }

  @media (min-width: 992px) {
    th {
      width: 200px;
    }
  }

  @media (min-width: 1200px) {
    th {
      width: 250px;
    }
  }
`

const TdCenter = styled.td`
  text-align: center;
`

const Footnote = styled.p`
  font-size: 0.8rem;
  line-height: 1.5;
  padding-left: 4rem;
  text-indent: -4rem;
`

const SmallText = styled.i`
  color: #aaa;
`

const Logo = styled.img`
  width: 100%;
`

const StyledRating = styled.div`
  font-size: 1.2rem;
  color: #ffbb00;
`

const Rating = ({ rating }) => {
  const stars = Math.floor(rating)
  const halfStar = rating % 1 > 0

  return (
    <StyledRating>
      {[...Array(stars)].map(i => (
        <FaStar key={i} />
      ))}
      {halfStar && <FaStarHalfAlt />}
    </StyledRating>
  )
}

const Background = ({ className, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "stock/oil-2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <BackgroundImage
      className={className}
      fluid={data.image.childImageSharp.fluid}
      style={{ height: "100%" }}
    >
      {children}
    </BackgroundImage>
  )
}

const WinnerBox = styled(Row)`
  border-radius: 4px;
  border: 5px solid
    ${props => (props.red ? "#ec5d5d" : props.yellow ? "#fdff61" : "#58cb53f7")};
`

const WinnerHighlight = styled(Col)`
  background: ${props =>
    props.red ? "#ec5d5d" : props.yellow ? "#fdff61" : "#58cb53f7"};
  text-align: center;
  color: #000;

  p {
    text-align: center;
  }
`

const ContentWrapper = styled.div`
  padding: 15px;
`

const Paragraph = styled.p`
  ${props => props.m0 && "margin-bottom: 0;"}
  ${props =>
    props.right &&
    config(props).media["md"]`
    text-align: right;
  `}
`

const ColoredLink = styled.a`
  background-color: ${props => props.color};
`

const ProductHeadingWrapper = styled.div`
  ${props => props.desktop && "display: none;"}
  text-align: center;
  margin-bottom: 15px;

  ${props => config(props).media["md"]`
    display: ${props => (props.desktop ? "block" : "none")};
  `}
`

const StyledProductHeading = styled.h3`
  position: relative;
  display: inline-block;
  font-size: 1.3rem;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 15px;

  &::after {
    position: absolute;
    content: "";
    left: 50%;
    width: 85%;
    height: 1px;
    bottom: -10px;
    transform: translateX(-50%);
    background: #000;
  }
`

const SeparatorLine = styled.div`
  width: 75%;
  height: 2px;
  background: #666;
  margin: 100px auto 0 auto;
`

const DateRight = styled.p`
  text-align: right;
`

const ProductHeading = ({ rating, desktop = false, children }) => {
  return (
    <ProductHeadingWrapper desktop={desktop}>
      <StyledProductHeading>{children}</StyledProductHeading>
      <div>
        <Rating rating={rating} />
      </div>
    </ProductHeadingWrapper>
  )
}

const CollapseWrapper = styled.div`
  & .ReactCollapse--collapse {
    transition: height 300ms;
  }
`

const CbdOel = ({ location }) => {
  const [detailOpen, setDetailOpen] = useState(false)

  return (
    <Layout
      title="CBD Öl Vergleich"
      location={location}
      description="CBD Öl Testsieger 2022: ✔️ Hoher CBD Gehalt ✔️ Beste Wirkung ✔️ Günstigster Preis ✔️ Laboranalyse"
      comments={true}
      light
      mt0
    >
      <Hero heightVh={30}>
        <Background>
          <HeroContent align="flex-end">
            <Container>
              <Row>
                <Col>
                  <HeroHeading>CBD Öl</HeroHeading>
                </Col>
              </Row>
            </Container>
          </HeroContent>
        </Background>
      </Hero>
      <Container>
        <Row>
          <Col>
            <TableWrapper>
              <Table cellSpacing={0} cellPadding={0} id="vergleichstabelle">
                <tr>
                  <th aria-label="Produktbild"></th>
                  <TdCenter>
                    <StaticImage
                      src="../../assets/images/products/cbd-oel/hanfgefluester.png"
                      alt="Hanfgeflüster CBD Öl"
                      layout="constrained"
                      placeholder="blurred"
                      width={250}
                    />
                  </TdCenter>
                  <TdCenter>
                    <StaticImage
                      src="../../assets/images/products/cbd-oel/vitadol.png"
                      alt="Vitadol Gold"
                      layout="constrained"
                      placeholder="blurred"
                      width={250}
                    />
                  </TdCenter>
                  <TdCenter>
                    <StaticImage
                      src="../../assets/images/products/cbd-oel/cbd-nativ.png"
                      alt="CBD-Nativ Öl"
                      layout="constrained"
                      placeholder="blurred"
                      width={250}
                    />
                  </TdCenter>
                  <TdCenter>
                    <StaticImage
                      src="../../assets/images/products/cbd-oel/specialis.png"
                      alt="Specialis CBD Cannabis Öl"
                      layout="constrained"
                      placeholder="blurred"
                      width={250}
                    />
                  </TdCenter>
                </tr>
                <tr>
                  <th aria-label="Kauf Button"></th>
                  <TdCenter>
                    <HoverButton
                      href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2F10ml-vollspektrum-cbd-oel-10%2F"
                      category="cbd-oel"
                      action="hanfgefluester"
                      label="table-top"
                    >
                      Hier günstig kaufen
                      <img
                        src="https://t.adcell.com/p/view?promoId=297834&slotId=98699"
                        border="0"
                        width="1"
                        height="1"
                        alt=""
                      />
                    </HoverButton>
                  </TdCenter>
                  <TdCenter>
                    <HoverButton
                      href="https://t.adcell.com/p/click?promoId=203511&slotId=98699&param0=https%3A%2F%2Fcbdwelt.de%2F1000mg-cbd-oel-vitadol-gold"
                      category="cbd-oel"
                      action="vitadol"
                      label="table-top"
                    >
                      Hier günstig kaufen
                      <img
                        src="https://t.adcell.com/p/view?promoId=203511&slotId=98699"
                        border="0"
                        width="1"
                        height="1"
                        alt=""
                      />
                    </HoverButton>
                  </TdCenter>
                  <TdCenter>
                    <HoverButton
                      href="https://t.adcell.com/p/click?promoId=213726&slotId=98699&param0=https%3A%2F%2Falpex-cbd.com%2Fcbd-oel-10"
                      category="cbd-oel"
                      action="cbd-nativ"
                      label="table-top"
                    >
                      Hier günstig kaufen
                      <img
                        src="https://t.adcell.com/p/view?promoId=213726&slotId=98699"
                        border="0"
                        width="1"
                        height="1"
                        alt=""
                      />
                    </HoverButton>
                  </TdCenter>
                  <TdCenter>
                    <NoRecommendation>Keine Empfehlung</NoRecommendation>
                  </TdCenter>
                </tr>
                <tr>
                  <th>Produkt</th>
                  <td>Vollspektrum CBD Öl</td>
                  <td>Vitadol Gold</td>
                  <td>CBD-Nativ Öl</td>
                  <td>Specialis CBD Cannabis Öl</td>
                </tr>
                <tr>
                  <th>CBD Öl Test</th>
                  <td>
                    <Rating rating={5} />
                  </td>
                  <td>
                    <Rating rating={5} />
                  </td>
                  <td>
                    <Rating rating={3} />
                  </td>
                  <td>
                    <Rating rating={1} />
                  </td>
                </tr>
                <tr>
                  <th>CBD Öl Preis</th>
                  <td>
                    59,95 €<br />
                    <SmallText>(5.499,00 €/l)</SmallText>
                    <br />
                    <em>(inkl. Versand)</em>
                  </td>
                  <td>
                    55,90 €<br />
                    <SmallText>(5.590,00 €/l)</SmallText>
                  </td>
                  <td>
                    46,90 €<br />
                    <SmallText>(4.690,00 €/l)</SmallText>
                  </td>
                  <td>
                    89,95 €<br />
                    <SmallText>(8.995,00 €/l)</SmallText>
                    <br />
                    <em>(+4,99 € Versand)</em>
                  </td>
                </tr>
                <tr>
                  <th>Anbieter</th>
                  <td>
                    Hanfgeflüster
                    <br />
                    (aus Deutschland)
                  </td>
                  <td>
                    CBD Welt
                    <br />
                    (aus Deutschland)
                  </td>
                  <td>
                    CBD-Nativ
                    <br />
                    (aus Österreich)
                  </td>
                  <td>
                    Specialis CBD
                    <br />
                    (aus Dänemark)
                  </td>
                </tr>
                <tr>
                  <th>Inhalt CBD Öl</th>
                  <td>10 ml = 250 Tropfen</td>
                  <td>10 ml = 330 Tropfen</td>
                  <td>10 ml = 250 Tropfen</td>
                  <td>10 ml = 200 Tropfen</td>
                </tr>
                <tr>
                  <th>CBD Gehalt pro Tropfen</th>
                  <td>10% (4 mg CBD pro Tropfen)</td>
                  <td>10% (3 mg CBD pro Tropfen)</td>
                  <td>10% (4 mg CBD pro Tropfen)</td>
                  <td>10% (3 mg CBD pro Tropfen)</td>
                </tr>
                <tr>
                  <th>CBD Öl Wirkung</th>
                  <td>Sehr gut</td>
                  <td>Sehr gut</td>
                  <td>Gut</td>
                  <td>Schlecht</td>
                </tr>
                <tr>
                  <th>Geschmack</th>
                  <td>
                    Unmerklicher Geschmack
                    <br />
                    (CBD Öl ist nicht zu schmecken)
                  </td>
                  <td>
                    Angenehmer milder Geschmack
                    <br />
                    (CBD Öl ist nicht zu schmecken)
                  </td>
                  <td>
                    Hanfiger Geschmack
                    <br />
                    (CBD Öl schmeckt etwas bitter)
                  </td>
                  <td>
                    Bitterer stechender Geschmack
                    <br />
                    (Fühlt sich pelzig auf der Zunge an)
                  </td>
                </tr>
                <tr>
                  <th>Variationen</th>
                  <td>
                    Vorhanden (CBD-Melatonin-Öl, Sport CBD Öl, Kurkuma CBD Öl)
                  </td>
                  <td>Minze</td>
                  <td>Nicht vorhanden</td>
                  <td>Nicht vorhanden</td>
                </tr>
                <tr>
                  <th>Vegetarisch und Vegan</th>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCheck />
                  </td>
                  <td>
                    <IconCross />
                  </td>
                </tr>
                <tr>
                  <th>CBD Zertifikat</th>
                  <td>TÜV Süd</td>
                  <td>Aquatest a.s. Prag</td>
                  <td>Hubertus Analytik Spittal (AT)</td>
                  <td>Unbekannt</td>
                </tr>
                <tr>
                  <th>Lieferzeit</th>
                  <td>2 Tage</td>
                  <td>2 Tage</td>
                  <td>4 Tage</td>
                  <td>12 Tage</td>
                </tr>
                <tr>
                  <th aria-label="Kauf Button"></th>
                  <TdCenter>
                    <HoverButton
                      href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2F10ml-vollspektrum-cbd-oel-10%2F"
                      category="cbd-oel"
                      action="hanfgefluester"
                      label="table-bottom"
                    >
                      Hier günstig kaufen
                    </HoverButton>
                  </TdCenter>
                  <TdCenter>
                    <HoverButton
                      href="https://t.adcell.com/p/click?promoId=203511&slotId=98699&param0=https%3A%2F%2Fcbdwelt.de%2F1000mg-cbd-oel-vitadol-gold"
                      category="cbd-oel"
                      action="vitadol"
                      label="table-bottom"
                    >
                      Hier günstig kaufen
                    </HoverButton>
                  </TdCenter>
                  <TdCenter>
                    <HoverButton
                      href="https://t.adcell.com/p/click?promoId=213726&slotId=98699&param0=https%3A%2F%2Falpex-cbd.com%2Fcbd-oel-10"
                      category="cbd-oel"
                      action="cbd-nativ"
                      label="table-bottom"
                    >
                      Hier günstig kaufen
                    </HoverButton>
                  </TdCenter>
                  <TdCenter>
                    <NoRecommendation>Keine Empfehlung</NoRecommendation>
                  </TdCenter>
                </tr>
              </Table>
            </TableWrapper>
          </Col>
        </Row>
        <Block>
          <Heading2>Übersicht</Heading2>
          <Row>
            <Col>
              <TableOfContents>
                <li>
                  <a href="#vergleichstabelle">Vergleichstabelle</a>
                </li>
                <li>
                  <a href="#empfehlungen">
                    Ausgezeichnete CBD Öl Empfehlungen für Ihren Kauf
                  </a>
                  <ol>
                    <li>
                      <ColoredLink color="#58cb53f7" href="#hohe-empfehlung">
                        Hohe CBD Öl Empfehlung
                      </ColoredLink>
                    </li>
                    <li>
                      <ColoredLink color="#fdff61" href="#mittlere-empfehlung">
                        Mittlere CBD Öl Empfehlung
                      </ColoredLink>
                    </li>
                    <li>
                      <ColoredLink color="#ec5d5d" href="#geringe-empfehlung">
                        Geringe bzw. keine CBD Öl Empfehlung
                      </ColoredLink>
                    </li>
                  </ol>
                </li>
                <li>
                  <a href="#wissenswertes">Wissenswertes zu CBD Öl</a>
                  <ol>
                    <li>
                      <a href="#warum-cbd">
                        Warum CBD Öl kaufen bzw. was bringt CBD Öl?
                      </a>
                    </li>
                    <li>
                      <a href="#einnahme">CBD Öl Einnahme</a>
                    </li>
                    <li>
                      <a href="#dosierung">
                        Wie dosiert man CBD Öl bzw. wieviel CBD Öl sollte man
                        einnehmen?
                      </a>
                    </li>
                    <li>
                      <a href="#wirkungseintritt">CBD Öl Wirkung wann?</a>
                    </li>
                    <li>
                      <a href="#wirkungsdauer">CBD Öl Wirkung wie lange?</a>
                    </li>
                    <li>
                      <a href="#legalitaet">Ist CBD Öl legal?</a>
                    </li>
                    <li>
                      <a href="#nebenwirkungen">Nebenwirkungen CBD Öl</a>
                    </li>
                    <li>
                      <a href="#sucht">Macht CBD Öl abhängig?</a>
                    </li>
                    <li>
                      <a href="#high">Macht CBD Öl high?</a>
                    </li>
                    <li>
                      <a href="#geschmack">Wie schmeckt CBD Öl?</a>
                    </li>
                    <li>
                      <a href="#lagerung">
                        Wie sollte man CBD Öl am besten lagern?
                      </a>
                    </li>
                  </ol>
                </li>
              </TableOfContents>
            </Col>
          </Row>
        </Block>
        <Block>
          <Heading2 id="empfehlungen">
            Ausgezeichnete CBD Öl Empfehlungen für Ihren Kauf
          </Heading2>
        </Block>
        <Block>
          <Heading2 id="hohe-empfehlung">Hohe CBD Öl Empfehlung</Heading2>
          <WinnerBox align="stretch">
            <WinnerHighlight xs={12} md={6} lg={4} justify="center">
              <ContentWrapper>
                <ProductHeading rating={5}>
                  Testsieger CBD Öl Hanfgeflüster
                </ProductHeading>
                <TrackedLink
                  href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2F10ml-vollspektrum-cbd-oel-10%2F"
                  category="cbd-oel"
                  action="hanfgefluester"
                  label="bullet-image"
                >
                  <StaticImage
                    src="../../assets/images/products/cbd-oel/hanfgefluester.png"
                    alt="Hanfgeflüster"
                    layout="constrained"
                    placeholder="blurred"
                    width={250}
                  />
                </TrackedLink>
                <p>🇩🇪 Hergestellt in Berlin</p>
                <p>👨🏼‍🔬 Test CBD Öl Wirkgehalt durch TÜV SÜD</p>
                <p>🌳 1 Baum für jedes Produkt</p>
                <p>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2F10ml-vollspektrum-cbd-oel-10%2F"
                    width={14}
                    category="cbd-oel"
                    action="hanfgefluester"
                    label="bullet"
                  >
                    CBD Öl kaufen
                  </ArrowButton>
                </p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={5} desktop>
                  Testsieger CBD Öl Hanfgeflüster
                </ProductHeading>
                <p>
                  Der <strong>Testsieger in unserem CBD Öl Vergleich</strong>{" "}
                  ist das 10%-ige CBD Öl von Hanfgeflüster. Es überzeugt uns von
                  seiner sehr guten Wirkung bei einem{" "}
                  <strong>CBD Gehalt von 4 mg CBD pro Tropfen</strong>. Bei
                  einer täglichen Einnahme von vier Tropfen sind das 16 mg CBD
                  pro Tag. In einem CBD Öl Fläschchen sind ca. 250 Tropfen
                  enthalten. Auch der angenehme milde Geschmack hat uns im
                  Vergleich zu anderen CBD Ölen überzeugt. Der typisch bittere
                  CBD Öl Geschmack war nicht zu schmecken und wird{" "}
                  <strong>in Rezensionen von anderen Kunden empfohlen</strong>.
                </p>
                <p>
                  Das 10% CBD Öl von Hanfgeflüster unterliegt höchsten
                  Qualitätsstandards und wird u.a. vom{" "}
                  <a
                    href="https://www.tuvsud.com/de-de"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    TÜV Süd
                  </a>
                  , einer unabhängigen deutschen Kontrollprüfstelle, getestet
                  und zertifiziert. Hanfgeflüster ist unter den CBD Öl Anbietern
                  eine bekannte und für Viele eine vertrauenswürdige Marke. Mit
                  dem direkten Versand des CBD Öls aus Deutschland kann eine
                  schnelle Lieferung innerhalb von zwei Werktagen garantiert
                  werden und das ohne Versandkosten. Eine Flasche 10% CBD Öl in
                  der Packungsgröße 10 ml kostet 59,95€. Der Preis liegt
                  verglichen mit anderen CBD Öl Anbietern im mittleren Bereich
                  und ist nicht zu teuer. Zusammenfassend erhält das 10%-ige{" "}
                  <TrackedLink
                    href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2F10ml-vollspektrum-cbd-oel-10%2F"
                    category="cbd-oel"
                    action="hanfgefluester"
                    label="text"
                  >
                    CBD Öl von Hanfgelüster
                  </TrackedLink>{" "}
                  5 von 5 Sternen, weil es{" "}
                  <strong>im Preis-Leistungs-Verhältnis am besten</strong>{" "}
                  abschneidet und deshalb{" "}
                  <strong>als Testsieger eine 100%-ige Weiterempfehlung</strong>{" "}
                  verdient.
                </p>
                <Paragraph right>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2F10ml-vollspektrum-cbd-oel-10%2F"
                    category="cbd-oel"
                    action="hanfgefluester"
                    label="text-button"
                    width={18}
                  >
                    Weiter informieren
                  </ArrowButton>
                </Paragraph>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
        <Block>
          <WinnerBox align="stretch">
            <WinnerHighlight xs={12} md={6} lg={4} justify="center">
              <ContentWrapper>
                <ProductHeading rating={5}>
                  Qualitätssieger Vitadol Gold
                </ProductHeading>
                <TrackedLink
                  href="https://t.adcell.com/p/click?promoId=203511&slotId=98699&param0=https%3A%2F%2Fcbdwelt.de%2F1000mg-cbd-oel-vitadol-gold"
                  category="cbd-oel"
                  action="vitadol"
                  label="bullet-image"
                >
                  <StaticImage
                    src="../../assets/images/products/cbd-oel/vitadol.png"
                    alt="Vitadol Gold"
                    layout="constrained"
                    placeholder="blurred"
                    width={250}
                  />
                </TrackedLink>
                <p>✔️ 330 Tropfen im CBD Öl Vergleich</p>
                <p>🌿 Mit und ohne Minzgeschmack</p>
                <p>💶 30 Tage Geld zurück Garantie</p>
                <p>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=203511&slotId=98699&param0=https%3A%2F%2Fcbdwelt.de%2F1000mg-cbd-oel-vitadol-gold"
                    width={14}
                    category="cbd-oel"
                    action="vitadol"
                    label="bullet"
                  >
                    CBD Öl kaufen
                  </ArrowButton>
                </p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={5} desktop>
                  Qualitätssieger Vitadol Gold
                </ProductHeading>
                <p>
                  Ein weiterer CBD Öl 10% Testsieger in unserem Vergleich ist
                  das Vitadol Gold CBD Öl 10%. Die Wirkung ist sehr gut bei
                  einem <strong>CBD Gehalt von 3 mg CBD pro Tropfen</strong>.
                  Bei einer täglichen Einnahme von vier Tropfen sind das 12 mg
                  CBD pro Tag. Die Flüssigkeit ist klar braun und den Geschmack
                  beschreiben unsere Probanden als angenehm mild. Unsere
                  Erfahrungen zeigen außerdem, dass das{" "}
                  <strong>Hanföl leicht verträglich</strong> ist. Wer ein wenig
                  Abwechslung haben möchte, kann sogar mit den Vitadol Ölen
                  Complex oder Minze variieren. Der Anbieter verspricht eine
                  schnelle Lieferung innerhalb von zwei Tagen. Wenn man den
                  Preis mit anderen Anbietern von CBD Ölen vergleicht, liegt das
                  Vitadol Gold CBD Öl mit einem Preis von 55,90 €, inklusive
                  Versand, im absoluten Rahmen. Daher erhält das{" "}
                  <TrackedLink
                    href="https://t.adcell.com/p/click?promoId=203511&slotId=98699&param0=https%3A%2F%2Fcbdwelt.de%2F1000mg-cbd-oel-vitadol-gold"
                    category="cbd-oel"
                    action="vitadol"
                    label="text"
                  >
                    Vitadol Gold
                  </TrackedLink>{" "}
                  5 von 5 Sternen, weil die{" "}
                  <strong>
                    hohe Qualität und die sehr gute Wirkung sowie die
                    Produktvariationen
                  </strong>{" "}
                  den Preis rechtfertigen.
                </p>
                <Paragraph right>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=203511&slotId=98699&param0=https%3A%2F%2Fcbdwelt.de%2F1000mg-cbd-oel-vitadol-gold"
                    width={18}
                    category="cbd-oel"
                    action="vitadol"
                    label="text-button"
                  >
                    Weiter informieren
                  </ArrowButton>
                </Paragraph>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
        <Block>
          <WinnerBox align="stretch">
            <WinnerHighlight xs={12} md={6} lg={4} justify="center">
              <ContentWrapper>
                <ProductHeading rating={5}>
                  Beste Wirkung CBD Öl Malantis
                </ProductHeading>
                <TrackedLink
                  href="https://t.adcell.com/p/click?promoId=251098&slotId=98699&param0=https%3A%2F%2Fmalantis.de%2Fprodukt%2Fmalantis-cbd-oel-10%2F"
                  category="cbd-oel"
                  action="malantis"
                  label="bullet-image"
                >
                  <StaticImage
                    src="../../assets/images/products/cbd-oel/malantis.png"
                    alt="Malantis CBD Hanf Öl"
                    layout="constrained"
                    placeholder="blurred"
                    width={250}
                  />
                </TrackedLink>
                <p>✔️ Test: Hoher CBD Gehalt</p>
                <p>🌿 Mit und ohne Schwarzkümmelöl</p>
                <p>💶 10€ Rabatt bei Newsletter Anmeldung</p>
                <p>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=251098&slotId=98699&param0=https%3A%2F%2Fmalantis.de%2Fprodukt%2Fmalantis-cbd-oel-10%2F"
                    width={14}
                    category="cbd-oel"
                    action="malantis"
                    label="bullet"
                  >
                    CBD Öl kaufen
                  </ArrowButton>
                </p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={5} desktop>
                  Beste Wirkung CBD Öl Malantis
                </ProductHeading>
                <p>
                  Mindestens genauso gut wie die vorherigen Testsieger in
                  unserem CBD Öl Vergleich ist das Malantis CBD Hanf Öl. Es
                  überzeugt durch die{" "}
                  <strong>sehr gute Wirkung des CBDs</strong> und dem
                  unmerklichen Geschmack des Öls bei der Einnahme. Zudem ist das
                  CBD Öl auch mit dem Zusatz von Schwarzkümmel Öl sehr gut
                  verträglich. Eine Qualitätsprüfung der Produktreinheit wird
                  durch <strong>deutsche Kontrollstellen</strong>{" "}
                  sichergestellt. In dem Vergleichstest entspricht außerdem der
                  Verkaufspreis in Höhe von 54,95 € einem sehr guten
                  Preis-Leistungs-Verhältnis. Es kommen keine Lieferkosten hinzu
                  und die Lieferung wird in 1-3 Tagen innerhalb Deutschlands
                  garantiert. Aus diesem Grund erhält das{" "}
                  <TrackedLink
                    href="https://t.adcell.com/p/click?promoId=251098&slotId=98699&param0=https%3A%2F%2Fmalantis.de%2Fprodukt%2Fmalantis-cbd-oel-10%2F"
                    category="cbd-oel"
                    action="malantis"
                    label="text"
                  >
                    Malantis CBD Hanf Öl
                  </TrackedLink>{" "}
                  ebenfalls 5 von 5 Sternen und zählt deshalb zu den{" "}
                  <strong>Cannabisöl Testsiegern</strong>.
                </p>
                <Paragraph right>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=251098&slotId=98699&param0=https%3A%2F%2Fmalantis.de%2Fprodukt%2Fmalantis-cbd-oel-10%2F"
                    width={18}
                    category="cbd-oel"
                    action="malantis"
                    label="text-button"
                  >
                    Weiter informieren
                  </ArrowButton>
                </Paragraph>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
        <Block>
          <Heading2 id="mittlere-empfehlung">
            Mittlere CBD Öl Empfehlung
          </Heading2>
          <WinnerBox align="stretch" yellow>
            <WinnerHighlight xs={12} md={6} lg={4} justify="center" yellow>
              <ContentWrapper>
                <ProductHeading rating={4}>
                  Zertifiziertes CBD Öl Swiss FX
                </ProductHeading>
                <TrackedLink
                  href="https://t.adcell.com/p/click?promoId=230476&slotId=98699&param0=https%3A%2F%2Fswissfx.de%2Fproducts%2F10-cbd-oel"
                  category="cbd-oel"
                  action="swissfx"
                  label="bullet-image"
                >
                  <StaticImage
                    src="../../assets/images/products/cbd-oel/swissfx.png"
                    alt="SwissFX"
                    layout="constrained"
                    placeholder="blurred"
                    width={250}
                  />
                </TrackedLink>
                <p>👨🏼‍🔬 Test CBD Öl Wirkgehalt durch TÜV SÜD</p>
                <p>⛰️ Hanf aus Schweizer Landwirtschaft</p>
                <p>📦 Ohne Versandkosten</p>
                <p>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=230476&slotId=98699&param0=https%3A%2F%2Fswissfx.de%2Fproducts%2F10-cbd-oel"
                    width={14}
                    category="cbd-oel"
                    action="swissfx"
                    label="bullet"
                  >
                    CBD Öl kaufen
                    <img
                      src="https://t.adcell.com/p/view?promoId=230476&slotId=98699"
                      border="0"
                      width="1"
                      height="1"
                      alt=""
                    />
                  </ArrowButton>
                </p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={4} desktop>
                  Zertifiziertes CBD Öl Swiss FX
                </ProductHeading>
                <p>
                  Eine mittlere Weiterempfehlung aufgrund von{" "}
                  <strong>guten Testergebnissen</strong> erhält das Swiss FX CBD
                  Öl. Es ist mit einem Preis von 49,95 € etwas günstiger als
                  einige CBD Öl 10% Testsieger, aber weist zudem{" "}
                  <strong>
                    gute bis sehr gute Ergebnisse in dem Kriterium Wirkung
                  </strong>{" "}
                  auf. Die Qualität des Cannabisöls wird{" "}
                  <strong>
                    vom{" "}
                    <a
                      href="https://www.tuvsud.com/de-de"
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      TÜV Süd
                    </a>{" "}
                    geprüft und zertifiziert
                  </strong>{" "}
                  - das schätzen wir in unserem CBD Öl Vergleich sehr. In den
                  Rezessionen geben einige Kunden allerdings an, dass das CBD Öl
                  etwas erdig schmeckt, aber insgesamt einen milden Geschmack
                  hat. Andere Geschmacksrichtungen, beispielsweise mit
                  Fruchtaromen erweitert, sind nicht vorhanden. Eine schnelle
                  Lieferung innerhalb Deutschlands von 1-3 Tagen runden das
                  Testurteil von{" "}
                  <TrackedLink
                    href="https://t.adcell.com/p/click?promoId=230476&slotId=98699&param0=https%3A%2F%2Fswissfx.de%2Fproducts%2F10-cbd-oel"
                    category="cbd-oel"
                    action="swissfx"
                    label="text"
                  >
                    Swiss FX CBD Öl
                  </TrackedLink>{" "}
                  mit 4 von 5 Sternen ab.
                </p>
                <Paragraph right>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=230476&slotId=98699&param0=https%3A%2F%2Fswissfx.de%2Fproducts%2F10-cbd-oel"
                    width={18}
                    category="cbd-oel"
                    action="swissfx"
                    label="text-button"
                  >
                    Weiter informieren
                  </ArrowButton>
                </Paragraph>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
        <Block>
          <WinnerBox align="stretch" yellow>
            <WinnerHighlight xs={12} md={6} lg={4} justify="center" yellow>
              <ContentWrapper>
                <ProductHeading rating={3.5}>
                  Günstigstes CBD Öl Cannadol
                </ProductHeading>
                <TrackedLink
                  href="https://t.adcell.com/p/click?promoId=203511&slotId=98699&param0=https%3A%2F%2Fcbdwelt.de%2Fcannadol-cbd-oel-10-classic-bio-cbd-hanfoel-tropfen"
                  category="cbd-oel"
                  action="cannadol"
                  label="bullet-image"
                >
                  <StaticImage
                    src="../../assets/images/products/cbd-oel/cannadol.png"
                    alt="Cannadol Classic"
                    layout="constrained"
                    placeholder="blurred"
                    width={250}
                  />
                </TrackedLink>
                <p>💶 Günstigster CBD Öl Preis im Vergleich</p>
                <p>🍋 Mit und ohne Zitronengeschmack</p>
                <p>✔️ Angenehm säuerlicher Geschmack</p>
                <p>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=203511&slotId=98699&param0=https%3A%2F%2Fcbdwelt.de%2Fcannadol-cbd-oel-10-classic-bio-cbd-hanfoel-tropfen"
                    width={14}
                    category="cbd-oel"
                    action="cannadol"
                    label="bullet"
                  >
                    CBD Öl kaufen
                    <img
                      src="https://t.adcell.com/p/view?promoId=203511&slotId=98699"
                      border="0"
                      width="1"
                      height="1"
                      alt=""
                    />
                  </ArrowButton>
                </p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={3.5} desktop>
                  Günstigstes CBD Öl Cannadol
                </ProductHeading>
                <p>
                  Das <strong>günstigste CBD Öl</strong> in unserem Vergleich
                  ist das Cannadol CBD Öl. Es kostet lediglich 37,99 € und ist
                  damit günstiger als unsere CBD Öl Testsieger. Abstriche
                  bekommt das Cannadol CBD Öl jedoch bei der CBD Wirkung.{" "}
                  <strong>Im Vergleich zu unseren Testsiegern</strong> ist der
                  Wirkeffekt in unseren Untersuchungen eher gut statt sehr gut.
                  Das CBD Öl eignet sich daher vor allem für diejenigen, die CBD
                  Öl 10% ausprobieren wollen und dabei nicht viel Geld ausgeben
                  wollen. Der Geschmack ist angenehm säuerlich. Das CBD Öl
                  kaufen, kann man mit der Geschmacksrichtung Zitrone oder auch
                  ohne Geschmacksaromen. Das Cannabisöl wird exklusive
                  Versandkosten i.H.v. 4,99 € innerhalb zwei Tagen geliefert. In
                  unserem Cannabisöl Test empfehlen wir das{" "}
                  <TrackedLink
                    href="https://t.adcell.com/p/click?promoId=203511&slotId=98699&param0=https%3A%2F%2Fcbdwelt.de%2Fcannadol-cbd-oel-10-classic-bio-cbd-hanfoel-tropfen"
                    category="cbd-oel"
                    action="cannadol"
                    label="text"
                  >
                    Cannadol CBD Öl
                  </TrackedLink>{" "}
                  mit 3,5 von 5 Sternen als ein{" "}
                  <strong>gutes und solides Hanf Öl CBD</strong> weiter.
                </p>
                <Paragraph right>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=203511&slotId=98699&param0=https%3A%2F%2Fcbdwelt.de%2Fcannadol-cbd-oel-10-classic-bio-cbd-hanfoel-tropfen"
                    width={18}
                    category="cbd-oel"
                    action="cannadol"
                    label="text-button"
                  >
                    Weiter informieren
                  </ArrowButton>
                </Paragraph>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
        <Block>
          <WinnerBox align="stretch" yellow>
            <WinnerHighlight xs={12} md={6} lg={4} justify="center" yellow>
              <ContentWrapper>
                <ProductHeading rating={3}>Solides Öl CBD-Nativ</ProductHeading>
                <TrackedLink
                  href="https://t.adcell.com/p/click?promoId=213726&slotId=98699&param0=https%3A%2F%2Falpex-cbd.com%2Fcbd-oel-10"
                  category="cbd-oel"
                  action="cbd-nativ"
                  label="bullet-image"
                >
                  <StaticImage
                    src="../../assets/images/products/cbd-oel/cbd-nativ.png"
                    alt="CBD-Nativ"
                    layout="constrained"
                    placeholder="blurred"
                    width={250}
                  />
                </TrackedLink>
                <p>🌳 Biologisch und Vegan</p>
                <p>🌿 Hanfiger Geschmack</p>
                <p>💶 10% Rabatt bei Newsletter Anmeldung</p>
                <p>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=213726&slotId=98699&param0=https%3A%2F%2Falpex-cbd.com%2Fcbd-oel-10"
                    width={14}
                    category="cbd-oel"
                    action="cbd-nativ"
                    label="bullet"
                  >
                    CBD Öl kaufen
                  </ArrowButton>
                </p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={3} desktop>
                  Solides Öl CBD-Nativ
                </ProductHeading>
                <Paragraph m0>
                  Eine weitere <strong>solide Empfehlung</strong> für das
                  10%-ige CBD Öl ist das CBD-Nativ Öl aus Österreich. Das
                  Cannabis Öl ist sehr günstig zu kaufen für einen Preis von
                  43,99 €. Die hohen Qualitätsstandards werden vom Prüflabor{" "}
                  <a
                    href="https://hubertus-analytik.at/"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    Hubertus Analytik
                  </a>{" "}
                  aus Spittal/ Österreich getestet und zertifiziert. In unserem
                  Vergleichstest haben die Hanf Tropfen einen milden hanfigen
                  Geschmack, wobei der bittere Geschmack des Hanf Öls leicht zu
                  schmecken war. Den{" "}
                  <strong>
                    Wirkeffekt des CBDs in unserem Vergleich bewerten wir als
                    gut
                  </strong>
                  , obwohl einige Kundenrezensionen auf der Homepage des
                  Anbieters gegenteiliges behaupten.
                </Paragraph>
                <p>
                  Verschiedene Geschmacksrichtungen sind nicht vorhanden, aber
                  das CBD Öl ist, wie bei allen anderen Anbietern auch,
                  biologisch und vegan. Die Lieferung des Hanf Öl CBDs erfolgt
                  versandkostenfrei nach Deutschland innerhalb von 4-5 Tagen.
                  Alles in allem erhält das{" "}
                  <TrackedLink
                    href="https://t.adcell.com/p/click?promoId=213726&slotId=98699&param0=https%3A%2F%2Falpex-cbd.com%2Fcbd-oel-10"
                    category="cbd-oel"
                    action="cbd-nativ"
                    label="text"
                  >
                    CBD-Nativ Öl
                  </TrackedLink>{" "}
                  eine Empfehlung mit 3 von 5 Sternen in unserem CBD Öl Test.
                </p>
                <Paragraph right>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=213726&slotId=98699&param0=https%3A%2F%2Falpex-cbd.com%2Fcbd-oel-10"
                    category="cbd-oel"
                    action="cbd-nativ"
                    label="text-button"
                    width={18}
                  >
                    Weiter informieren
                  </ArrowButton>
                </Paragraph>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
        <Block>
          <WinnerBox align="stretch" yellow>
            <WinnerHighlight xs={12} md={6} lg={4} justify="center" yellow>
              <ContentWrapper>
                <ProductHeading rating={3}>
                  Klassisches CBD Öl Cannexol
                </ProductHeading>
                <TrackedLink
                  href="https://t.adcell.com/p/click?promoId=182029&slotId=98699&param0=https%3A%2F%2Fwww.cannhelp.com%2Fproducts%2Fcannexol-10-cbd"
                  category="cbd-oel"
                  action="cannexol"
                  label="bullet-image"
                >
                  <StaticImage
                    src="../../assets/images/products/cbd-oel/cannexol.png"
                    alt="Cannexol"
                    layout="constrained"
                    placeholder="blurred"
                    width={250}
                  />
                </TrackedLink>
                <p>
                  <StaticImage
                    src="../../assets/images/icons/flag_austria.png"
                    alt="Flagge Österreich"
                    layout="constrained"
                    placeholder="blurred"
                    width={20}
                    height={20}
                    style={{ marginTop: "5px" }}
                  />{" "}
                  Hanfsamenöl aus der Steiermark (AT)
                </p>
                <p>🌿 Bitterer Geschmack mit holzigem Charakter</p>
                <p>👍 Gütesiegel ARGE-Canna</p>
                <p>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=182029&slotId=98699&param0=https%3A%2F%2Fwww.cannhelp.com%2Fproducts%2Fcannexol-10-cbd"
                    width={14}
                    category="cbd-oel"
                    action="cannexol"
                    label="bullet"
                  >
                    CBD Öl kaufen
                    <img
                      src="https://t.adcell.com/p/view?promoId=182029&slotId=98699"
                      border="0"
                      width="1"
                      height="1"
                      alt=""
                    />
                  </ArrowButton>
                </p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={3} desktop>
                  Klassisches CBD Öl Cannexol
                </ProductHeading>
                <p>
                  Nach umfangreichen Tests und Analysen des Cannabisöl vom
                  österreichischen Hersteller Cannhelp empfehlen wir das
                  Cannexol CBD Öl 10 % mit 3 von 5 Sternen weiter. Wir bewerten
                  das Cannexol Hanföl als ein{" "}
                  <strong>klassisches und solides CBD Vollspektrum Öl</strong>.
                  Leider sind Angaben zur Anzahl der Tropfen in einem 10 ml
                  Fläschchen weder auf der Verpackung des Produkts noch auf der
                  Internetseite des CBD Herstellers angegeben. Daher gehen wir
                  von einer gängigen Pipette mit 0,05 ml pro Tropfen aus. Das
                  entspricht insgesamt 200 Tropfen in einem CBD Öl 10 ml
                  Fläschchen Cannexol. Im Umkehrschluss bedeutet es, dass{" "}
                  <strong>ein Tropfen 5 mg CBD</strong> enthält. Der CBD Gehalt
                  wird nach Angaben von Cannhelp vom{" "}
                  <a
                    href="https://www.hanfanalytik.at/"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    IFHA Wien
                  </a>{" "}
                  geprüft und zertifiziert. Die Analysenzertifikate des CBD Öls
                  können auf der Website von Cannhelp eingesehen werden.
                  Allerdings möchten wir als unabhängiger CBD Öl Tester darauf
                  aufmerksam machen, dass das Zertifikat im Vergleich zu anderen
                  Cannabisöl Anbietern, die ebenfalls mit dem Prüflabor{" "}
                  <a
                    href="https://www.hanfanalytik.at/"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    IFHA Wien
                  </a>{" "}
                  zusammenarbeiten, anders bzw. primitiver aussieht.
                </p>
                {!detailOpen && (
                  <p>
                    <a
                      href="#"
                      onClick={event => {
                        event.preventDefault()
                        setDetailOpen(true)
                      }}
                    >
                      weiterlesen...
                    </a>
                  </p>
                )}
                <CollapseWrapper>
                  <Collapse isOpened={detailOpen}>
                    <p>
                      Den <strong>Wirkeffekt des Cannexol CBD Öls</strong>{" "}
                      beurteilen wir nach der subjektiven Wahrnehmung mehrerer
                      Probanden als gut. Der Geschmack des CBD Produktes ist
                      bitter. Es hat einen holzigen Charakter mit Anklängen
                      eines herben Aromas. Andere Geschmacksvariationen wie bei
                      unseren CBD Öl Testsiegern mit hoher Weiterempfehlung gibt
                      es nicht.
                    </p>
                    <p>
                      Das Cannexol Hanf Öl hat einen{" "}
                      <strong>fairen Preis in Höhe von 54,90 €</strong> und
                      liegt damit im absoluten Rahmen. Zusätzliche Versandkosten
                      gibt es nicht und die Lieferung wird innerhalb von
                      durchschnittlich drei Tagen an Versandadressen in
                      Deutschland geliefert.
                    </p>
                    <p>
                      Fazit: Das{" "}
                      <TrackedLink
                        href="https://t.adcell.com/p/click?promoId=182029&slotId=98699&param0=https%3A%2F%2Fwww.cannhelp.com%2Fproducts%2Fcannexol-10-cbd"
                        category="cbd-oel"
                        action="cannexol"
                        label="text"
                      >
                        Cannexol CBD Öl 10%
                      </TrackedLink>{" "}
                      erhält in dem CBD Öl Vergleich 3 von 5 Sternen als ein
                      klassisches Vollspektrum Cannabisöl mit mittlerer
                      Empfehlung.
                    </p>
                  </Collapse>
                </CollapseWrapper>
                <Paragraph right>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=182029&slotId=98699&param0=https%3A%2F%2Fwww.cannhelp.com%2Fproducts%2Fcannexol-10-cbd"
                    category="cbd-oel"
                    action="cannexol"
                    label="text-button"
                    width={18}
                  >
                    Weiter informieren
                  </ArrowButton>
                </Paragraph>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
        <Block>
          <WinnerBox align="stretch" yellow>
            <WinnerHighlight xs={12} md={6} lg={4} justify="center" yellow>
              <ContentWrapper>
                <ProductHeading rating={3}>
                  Durchschnittliches CBD Öl Biobloom
                </ProductHeading>
                <TrackedLink
                  href="https://t.adcell.com/p/click?promoId=268531&slotId=98699&param0=https%3A%2F%2Fbiobloom-cbd.de%2Fprodukt%2F10-prozent-bio-cbd-oel-10ml%2F"
                  category="cbd-oel"
                  action="biobloom"
                  label="bullet-image"
                >
                  <StaticImage
                    src="../../assets/images/products/cbd-oel/biobloom.png"
                    alt="Biobloom CBD Öl"
                    layout="constrained"
                    placeholder="blurred"
                    width={250}
                  />
                </TrackedLink>
                <p>👨🏼‍🔬 Test CBD Öl Wirkgehalt durch IFHA Wien</p>
                <p>🌳 Verwendung von Bio Hanfsamenöl</p>
                <p>⭐ 3 Sterne CBD Öl Bewertung</p>
                <p>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=268531&slotId=98699&param0=https%3A%2F%2Fbiobloom-cbd.de%2Fprodukt%2F10-prozent-bio-cbd-oel-10ml%2F"
                    width={14}
                    category="cbd-oel"
                    action="biobloom"
                    label="bullet"
                  >
                    CBD Öl kaufen
                    <img
                      src="https://t.adcell.com/p/view?promoId=268531&slotId=98699"
                      border="0"
                      width="1"
                      height="1"
                      alt=""
                    />
                  </ArrowButton>
                </p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={3} desktop>
                  Durchschnittliches CBD Öl Biobloom
                </ProductHeading>
                <p>
                  Eine <strong>Weiterempfehlung mit 3 Sternen</strong> erhält
                  das Biobloom CBD Öl. Es ist ein Vollspektrum Bio CBD Öl und
                  für alle empfohlen, denen der{" "}
                  <strong>typisch bittere und hanfige</strong> Geschmack von
                  Hanf Öl nichts ausmacht. Geschmacksvariationen der Hanf
                  Tropfen im Vergleich zu anderen CBD Öl Präparaten gibt es
                  nicht. In unserem CBD Öl Vergleichstest lagen die
                  Analysenzertifikate zur Auswertung vor. Die Qualität wird vom{" "}
                  <a
                    href="https://www.hanfanalytik.at/"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    Institut für Hanfanalytik Wien
                  </a>{" "}
                  geprüft. Die{" "}
                  <strong>
                    CBD Wirkung ist gut mit einem nachgewiesenen und
                    zertifizierten CBD Gehalt von 7,52 %
                  </strong>
                  . Das Cannabisöl kommt aus Österreich und wer die CBD Tropfen
                  kaufen möchte, muss mit einer durchschnittlichen Lieferzeit
                  von vier Tagen nach Deutschland rechnen. Es ist
                  versandkostenfrei und das{" "}
                  <TrackedLink
                    href="https://t.adcell.com/p/click?promoId=268531&slotId=98699&param0=https%3A%2F%2Fbiobloom-cbd.de%2Fprodukt%2F10-prozent-bio-cbd-oel-10ml%2F"
                    category="cbd-oel"
                    action="biobloom"
                    label="text"
                  >
                    Bio CBD Öl
                  </TrackedLink>{" "}
                  kostet in unserem Preisvergleich 57,90 €, welches damit etwas
                  teurer ist als unsere CBD Öl Testsieger.
                </p>
                <Paragraph right>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=268531&slotId=98699&param0=https%3A%2F%2Fbiobloom-cbd.de%2Fprodukt%2F10-prozent-bio-cbd-oel-10ml%2F"
                    width={18}
                    category="cbd-oel"
                    action="biobloom"
                    label="text-button"
                  >
                    Weiter informieren
                  </ArrowButton>
                </Paragraph>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
        <Block>
          <Heading2 id="geringe-empfehlung">
            Geringe bzw. keine CBD Öl Empfehlung
          </Heading2>
          <WinnerBox align="stretch" red>
            <WinnerHighlight xs={12} md={6} lg={4} justify="center" red>
              <ContentWrapper>
                <ProductHeading rating={2}>Intensives CBD Öl</ProductHeading>
                <TrackedLink
                  href="https://t.adcell.com/p/click?promoId=203511&slotId=98699&param0=https%3A%2F%2Fcbdwelt.de%2Fmedihemp-cbd-oel-10-hanf-natural"
                  category="cbd-oel"
                  action="medihemp"
                  label="bullet-image"
                >
                  <StaticImage
                    src="../../assets/images/products/cbd-oel/medihemp.png"
                    alt="Medihemp"
                    layout="constrained"
                    placeholder="blurred"
                    width={250}
                  />
                </TrackedLink>
                <p>❌ Teures CBD Öl im Test</p>
                <p>❌ Starker bitterer Geschmack</p>
                <p>📦 Ohne Versandkosten</p>
                <p>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=203511&slotId=98699&param0=https%3A%2F%2Fcbdwelt.de%2Fmedihemp-cbd-oel-10-hanf-natural"
                    width={14}
                    category="cbd-oel"
                    action="medihemp"
                    label="bullet"
                  >
                    CBD Öl kaufen
                    <img
                      src="https://t.adcell.com/p/view?promoId=203511&slotId=98699"
                      border="0"
                      width="1"
                      height="1"
                      alt=""
                    />
                  </ArrowButton>
                </p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={2} desktop>
                  Intensives CBD Öl
                </ProductHeading>
                <p>
                  Dass teure Produkte nicht immer Qualitätssieger in Vergleichen
                  sein müssen, sehen wir ebenfalls bei den Hanf Ölen. Ein{" "}
                  <strong>teures CBD Öl</strong> in Höhe von 71,50€ wies in
                  unserer qualitativen Forschungsuntersuchung eine mittelmäßige
                  Wirkung auf. Auch ein CBD <strong>Analysenzertifikat</strong>{" "}
                  von einem unabhängigen Prüflabor, wie es bei vielen anderen
                  CBD Öl Präparaten (z.B. vom{" "}
                  <a
                    href="https://www.tuvsud.com/de-de"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    TÜV Süd
                  </a>{" "}
                  oder{" "}
                  <a
                    href="https://www.hanfanalytik.at/"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    IFHA Wien
                  </a>
                  ) der Fall ist, <strong>liegt nicht vor</strong>. Das Cannabis
                  Öl hat einen <strong>stark bitteren Geschmack</strong> und
                  Geschmacksvariationen mit Fruchtaromen o.ä. sind nicht
                  vorhanden. Der Versand erfolgt versandkostenfrei innerhalb von
                  zwei Tagen für Bestellungen in Deutschland. Das Hanf Öl erhält
                  im Großen und Ganzen 2 von maximal 5 Sternen in unserem CBD Öl
                  Test und hat insofern eine{" "}
                  <strong>geringe Weiterempfehlung im Vergleich</strong> zu den
                  anderen CBD Ölen.
                </p>
                <Paragraph right>
                  <ArrowButton
                    href="https://t.adcell.com/p/click?promoId=203511&slotId=98699&param0=https%3A%2F%2Fcbdwelt.de%2Fmedihemp-cbd-oel-10-hanf-natural"
                    width={18}
                    category="cbd-oel"
                    action="medihemp"
                    label="text-button"
                  >
                    Weiter informieren
                  </ArrowButton>
                </Paragraph>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
        <Block>
          <WinnerBox align="stretch" red>
            <WinnerHighlight xs={12} md={6} lg={4} justify="center" red>
              <ContentWrapper>
                <ProductHeading rating={1}>
                  Schlechtes CBD Öl Specialis
                </ProductHeading>
                <StaticImage
                  src="../../assets/images/products/cbd-oel/specialis.png"
                  alt="Specialis"
                  layout="constrained"
                  placeholder="blurred"
                  width={250}
                />
                <p>❌ Schlechte CBD Öl Wirkung</p>
                <p>❌ Starker bitterer Geschmack</p>
                <p>❌ Lange Lieferzeit</p>
              </ContentWrapper>
            </WinnerHighlight>
            <Col xs={12} md={6} lg={8} justify="center">
              <ContentWrapper>
                <ProductHeading rating={1} desktop>
                  Schlechtes CBD Öl Specialis
                </ProductHeading>
                <Paragraph m0>
                  Besonders <strong>schlecht</strong> und damit als letztes
                  schneidet in unserem CBD Test das 10%-ige Specialis CBD
                  Cannabis Öl ab. Mit einem exorbitant{" "}
                  <strong>hohen Preis von 89,95 €</strong> für 10 ml ist das CBD
                  Öl aus Dänemark mit Abstand das teuerste Hanf Öl in unserem
                  Vergleich. Das entspricht etwa 0,42 € pro Tropfen. Bei so
                  einem hohen Preis klingt die Qualität vielversprechend,
                  vergeblich. In unserem Test bewerteten Probanden den{" "}
                  <strong>Wirkeffekt des Hanföls als schlecht</strong> und auch
                  der <strong>bittere und stechende Geschmack</strong> auf der
                  Zunge wurde bemängelt.
                </Paragraph>
                <p>
                  Geschmacksvariationen sowie Analysenzertifikate vom Hersteller
                  oder einem unabhängigen Labor liegen nicht vor. Die
                  zusätzlichen Versandkosten in Höhe von 8,89 € und die lange{" "}
                  <strong>Lieferzeit von fast zwei Wochen</strong> führen
                  zusätzlich zu einer schlechten Bewertung mit lediglich einem
                  Stern und einem sehr weiten Abstand zum Cannabisöl Testsieger
                  in unserem CBD Öl Vergleich.
                </p>
              </ContentWrapper>
            </Col>
          </WinnerBox>
        </Block>
        <SeparatorLine />
        <Block mt={100}>
          <Heading2 id="wissenswertes">Wissenswertes zu CBD Öl</Heading2>
        </Block>
        <Block>
          <Heading2 id="warum-cbd">
            Warum CBD Öl kaufen bzw. was bringt CBD Öl?
          </Heading2>
          <p>
            Studien belegen, dass es ein großes therapeutisches Potenzial für
            CBD gibt<sup>1</sup>. Die Einnahme von Produkten mit CBD
            beispielsweise als CBD Öl oder{" "}
            <Link to="/vergleiche/cbd-fruchtgummis/">CBD Fruchtgummis</Link>{" "}
            verhelfen im Ganzen zu einem Allgemeinwohlsein. Es ist belegt, dass
            Cannabidiol z.B. als Öl bei den nachfolgenden Beschwerden hilft:
          </p>
          <p>
            <ul>
              <li>
                <strong>Angstzustände</strong>
              </li>
              <li>
                <strong>Unwohlsein</strong>
              </li>
              <li>
                <strong>Depressionszustände</strong>
              </li>
              <li>
                <strong>Hohes Stressempfinden</strong>
              </li>
              <li>
                <strong>Psychosen</strong>
              </li>
              <li>
                <strong>Schlafstörungen</strong>
              </li>
            </ul>
          </p>
          <Footnote>
            <sup>1</sup>{" "}
            <strong>
              Campos, Alline / Araujo Moreira, Fabricio / Villela, Felipe /
              Aparecida Del Bel, Elaine / Guimarães, Francisco (2012)
            </strong>
            : Multiple mechanisms involved in the large-spectrum therapeutic
            potential of cannabidiol in psychiatric disorders, in: Philos Trans
            R Soc Lond B Biol Sci., 1607, H. 367, S. 3364-3378,{" "}
            <a
              href="https://royalsocietypublishing.org/doi/10.1098/rstb.2011.0389"
              target="_blank"
              rel="noreferrer noopener"
            >
              DOI: 10.1098/rstb.2011.0389
            </a>
          </Footnote>
        </Block>
        <Block>
          <Row align="center" justify="center">
            <Col xs={12} lg={7}>
              <Heading2 id="einnahme">CBD Öl Einnahme</Heading2>
              <p>
                CBD Öl nimmt man am Besten <strong>sublingual</strong> zu sich.
                Dabei werden die Cannabisöl Tropfen beispielsweise der{" "}
                <a href="#hohe-empfehlung">CBD Öl Testsieger</a> mit der
                dazugehörigen Pipette direkt{" "}
                <strong>unter die Zunge getropft</strong>. Dort befindet sich
                eine große Vene, über welche das CBD Öl direkt in den
                Blutkreislauf gelangt, ohne vorher erst den Verdauungstrakt
                passieren zu müssen.
              </p>
              <p>
                Das Öl hält man{" "}
                <strong>
                  mindestens 30, besser jedoch 60 bis 90 Sekunden lang im Mund
                </strong>
                , bevor man es <strong>anschließend hinunterschluckt</strong>.
                Um die Wirkung zu verbessern, sollte zusätzlich eine halbe
                Stunde lang nach der Einnahme auf Essen und Trinken verzichten
                werden.
              </p>
              <p>
                Das CBD Öl darf in keinem Fall mit Hilfe eines Verdampfers oder
                Ähnlichem geraucht werden, da dabei nachgewiesen schädliche und
                sogar krebserregende Substanzen entstehen, wie z.B. Dioxine.
              </p>
            </Col>
            <Col xs={12} sm={10} md={6} lg={5}>
              <DivHelper mt={20}>
                <StaticImage
                  src="../../assets/images/stock/dropper.jpg"
                  placeholder="blurred"
                  layout="fullWidth"
                  alt="CBD Öl Pipette"
                />
              </DivHelper>
            </Col>
          </Row>
        </Block>
        <Block>
          <Heading2 id="dosierung">
            Wie dosiert man CBD Öl bzw. wie viel CBD Öl sollte man einnehmen?
          </Heading2>
          <p>
            Die optimale Dosierung von CBD Öl hängt von{" "}
            <strong>vielen verschiedenen Faktoren</strong> ab und kann je nach
            Person unterschiedlich sein. Neben körperlichen Faktoren wie Alter,
            Gewicht und Größe hängt die ideale Dosis auch davon ab, welche
            Beschwerden mit dem CBD gelindert werden sollen und wie stark diese
            ausgeprägt sind.
          </p>
          <p>
            Wer das CBD Öl 10% zum ersten Mal konsumiert, sollte mit einer{" "}
            <strong>geringen Dosierung von 2-3 Tropfen täglich anfangen</strong>
            , bis sich die gewünschte Hanföl Wirkung einstellt. Sobald sich die
            gewünschte Wirkung eingestellt hat, ist nicht mit weiteren Vorteilen
            zu rechnen, wenn man die Dosierung erhöht.
          </p>
          <p>
            Der{" "}
            <strong>
              CBD Gehalt pro Tropfen unterscheidet sich stark von Anbieter zu
              Anbieter
            </strong>
            . Zum einen sollte der CBD Gehalt von unabhängigen Instituten wie
            dem{" "}
            <a
              href="https://www.tuvsud.com/de-de"
              target="_blank"
              rel="noreferrer noopener"
            >
              TÜV Süd
            </a>
            , der{" "}
            <a
              href="https://de.wessling-group.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              Wessling Analytik Berlin
            </a>{" "}
            oder dem{" "}
            <a
              href="https://www.hanfanalytik.at/"
              target="_blank"
              rel="noreferrer noopener"
            >
              IFHA Wien
            </a>{" "}
            analysiert werden. Dabei wird die untersuchte{" "}
            <strong>CBD Konzentration in den Analysenzertifikaten</strong>{" "}
            bescheinigt. Zum anderen spielt die Pipette eine entscheidende
            Rolle. Bei einer Standardpipette hat ein Cannabisöl Tropfen 0,05 ml.
            Das entspricht bei einer Packungsgröße von 10 ml mit 10%
            konzentriertem CBD Öl 5 mg CBD pro Tropfen.{" "}
            <strong>
              Aus diesem Grunde lohnt sich ein Vergleich der{" "}
              <a href="#vergleichstabelle">CBD Öl Anbieter</a>
            </strong>
            . Im Umkehrschluss bedeutet das: Umso geringer der CBD Gehalt pro
            Tropfen, desto mehr Cannabisöl Tropfen müssen konsumiert werden.
            Einen übersichtlichen Vergleich der CBD Öl Testsieger mit dem
            höchsten CBD Gehalt für die Hanföl Wirkung und das CBD Zertifikat
            finden Sie hier.
          </p>
        </Block>
        <Block>
          <Heading2 id="wirkungseintritt">CBD Öl Wirkung wann?</Heading2>
          <p>
            Da es sich bei CBD um ein <strong>Naturprodukt</strong> handelt,
            gibt es genau wie bei der Dosierung viele Faktoren die beeinflussen,
            wie schnell die Wirkung von CBD Öl eintritt.
          </p>
          <p>
            Wenn das CBD Öl sublingual konsumiert wird, können die Wirkstoffe
            direkt in den Blutkreislauf gelangen, indem sie von der
            Mundschleimhaut absorbiert werden. So kann die Wirkung schon{" "}
            <strong>nach 15-30 Minuten</strong> eintreten. Das ist ein{" "}
            <Link to="/ratgeber/produkt-ratgeber/">wesentlicher Vorteil</Link>{" "}
            von CBD als Öl im Vergleich zu anderen CBD Produkten. Eine{" "}
            <strong>schnelle und spürbare Wirkung</strong> kann{" "}
            <strong>durch einen höheren CBD Gehalt</strong> erreicht werden.{" "}
            <strong>Vergleichen Sie</strong> in unserem CBD Öl Test die{" "}
            <a href="#vergleichstabelle">
              Wirkung der verschiedenen <strong>CBD Öl Anbieter</strong>
            </a>{" "}
            miteinander und lassen Sie sich von dem{" "}
            <a href="#hohe-empfehlung">CBD Öl Testsieger</a> selbst überzeugen.
          </p>
          <p>
            Je nach Beschwerden kann es zudem unterschiedlich lange dauern, bis
            das CBD eine spürbare Wirkung hervorruft. So können sich{" "}
            <strong>
              Verspannungen, innere Unruhe und Schlafprobleme üblicherweise
              bereits nach recht kurzer Zeit
            </strong>{" "}
            bessern. Erhofft man sich durch das CBD Öl eine Linderung von{" "}
            <strong>Angstzuständen, Depressionen oder Schmerzen</strong> muss
            man davon ausgehen, dass es{" "}
            <strong>mehrere Tage oder sogar Wochen</strong> dauern kann, bis
            eine Wirkung eintritt.
          </p>
        </Block>
        <Block>
          <Row align="center" justify="center">
            <Col xs={12} lg={7}>
              <Heading2 id="wirkungsdauer">CBD Öl Wirkung wie lange?</Heading2>
              <p>
                Ebenso wie die Dosierung und der Wirkungseintritt ist auch die{" "}
                <strong>
                  Wirkungsdauer von CBD Öl individuell sehr unterschiedlich
                </strong>{" "}
                und somit unter anderem von Körpergewicht und -größe abhängig.
                Es lohnt sich also, vorsichtig verschiedene Abstände zwischen
                einzelnen Dosierungen auszuprobieren, um die für sich persönlich
                optimale Wirkung zu erlangen.
              </p>
              <p>
                Üblicherweise kann man davon ausgehen, dass die Wirkung von
                Cannabidiol im Körper <strong>ungefähr 4-6 Stunden lang</strong>{" "}
                anhält. Eine <strong>längere und spürbare Wirkung</strong> kann{" "}
                <strong>durch einen höheren CBD Gehalt</strong> erreicht werden.
                Finden Sie in unseren CBD Öl Empfehlungen die{" "}
                <a href="#hohe-empfehlung">
                  Cannabisöl Testsieger mit hohem CBD Gehalt pro Tropfen
                </a>
                .
              </p>
            </Col>
            <Col xs={12} sm={10} md={6} lg={5}>
              <DivHelper mt={20}>
                <StaticImage
                  src="../../assets/images/stock/oil.jpg"
                  placeholder="blurred"
                  layout="fullWidth"
                  alt="CBD Öl"
                />
              </DivHelper>
            </Col>
          </Row>
        </Block>
        <Block>
          <Heading2 id="legalitaet">Ist CBD Öl legal?</Heading2>
          <p>
            Da das ebenfalls aus der Hanfpflanze gewonnene THC in Deutschland
            illegal ist, stellt sich verständlicherweise die Frage, ob CBD
            ebenfalls verboten ist.
          </p>

          <p>
            Die Antwort auf diese Frage ist eindeutig:{" "}
            <strong>CBD ist in Deutschland legal</strong>. Dabei ist es egal, ob
            CBD Öl oder{" "}
            <Link to="/vergleiche/cbd-fruchtgummis/">CBD Fruchtgummis</Link>,
            solange das Hanfprodukt{" "}
            <strong>unter einem THC Grenzwert von 0,2 % liegt</strong>, ist es
            auch legal (
            <a
              href="https://dejure.org/gesetze/BtMG/1.html"
              target="_blank"
              rel="noreferrer noopener"
            >
              §1 Abs. 1
            </a>{" "}
            i.V.m.{" "}
            <a
              href="https://dejure.org/gesetze/BtMG/Anlage_I.html"
              target="_blank"
              rel="noreferrer noopener"
            >
              Anlage 1 Spalte 3 Nr. b BtMG (Betäubungsmittelgesetz)
            </a>
            ).
          </p>

          <p>
            Wir empfehlen, dass das CBD Öl von einem vertrauenswürdigen
            Verkäufer zu beziehen. Wir sprechen eine klare Empfehlung darüber
            aus, CBD Öle zu kaufen, welche aus angebauten Hanfpflanzen innerhalb
            der EU hergestellt wurden. Seriöse Hersteller sollten auch immer
            regelmäßige Analysen von unabhängigen Laboren durchführen, um
            sicherzustellen, dass der für die Legalität ausschlaggebende{" "}
            <a
              href="https://www.bfarm.de/DE/Bundesopiumstelle/_FAQ/Cannabis/faq-liste.html?cms_fid=566334"
              target="_blank"
              rel="noreferrer noopener"
            >
              <strong>THC Grenzwert von maximal 0,2%</strong>
            </a>{" "}
            nicht überschritten wird. Der THC Wert, CBD Wert sowie andere
            Prüfparameter werden in den Analysenzertifikaten ausgewiesen. In
            unserem CBD Öl Vergleich geben wir die Prüfstelle des CBD
            Zertifikates an bzw. bestrafen den CBD Öl Anbieter in der Bewertung,
            wenn die Analysenzertifikate nicht vorliegen. Beispielsweise können
            die <strong>Analysenzertifikate der CBD Öl Testsieger</strong> auf
            den Webseiten der Anbieter einfach und öffentlich zugänglich
            eingesehen werden.
          </p>
        </Block>
        <Block>
          <Heading2 id="nebenwirkungen">Nebenwirkungen CBD Öl</Heading2>
          <p>
            Grundsätzlich handelt es sich bei Cannabidiol um eine{" "}
            <a
              href="https://www.bundesgesundheitsministerium.de/fileadmin/Dateien/5_Publikationen/Drogen_und_Sucht/Berichte/Broschuere/BMG_CaPris_A5_Info_web.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              <strong>sichere Substanz</strong>
            </a>
            . Nebenwirkungen treten meist{" "}
            <strong>nur bei deutlicher Überdosierung</strong> auf. Sollten Sie
            sich an alle Vorsichtsmaßnahmen und die Vorgaben des Herstellers
            halten, werden in der Regel auch keine Nebenwirkungen eintreten.
            Achten Sie bei dem CBD Öl Kauf auf die Angabe des Herstellers zu dem
            CBD Gehalt pro Tropen. In unserem CBD Öl Test führen wir die Angabe
            des <a href="#vergleichstabelle">CBD Gehalts pro Tropfen</a> der
            verschiedenen Anbieter auf.
          </p>
          <p>
            Denn, falls Sie zum ersten Mal CBD Öl konsumieren, empfehlen wir
            Ihnen mit <strong>einer geringen Dosierung zu beginnen</strong>{" "}
            (z.B. durch weniger Hanf Tropfen) und diese nur langsam zu erhöhen,
            um die Verträglichkeit auszutesten. Wenn Nebenwirkungen auftreten,
            sollte die Einnahme <strong>sofort abgebrochen</strong> werden.
          </p>
          <p>
            Sollten Sie verschreibungspflichtige{" "}
            <strong>Medikamente oder Psychopharmaka</strong> konsumieren,
            empfehlen wir Ihnen vor der Einnahme mit einem Arzt oder Apotheker
            zu sprechen, um Wechselwirkungen auszuschließen.
          </p>
          <p>
            Während der <strong>Schwangerschaft und Stillzeit</strong> darf CBD
            überhaupt nicht konsumiert werden. Auch <strong>Kinder</strong>{" "}
            dürfen kein Cannabidiol zu sich nehmen.
          </p>
        </Block>
        <Block>
          <Heading2 id="sucht">Macht CBD Öl abhängig?</Heading2>
          <p>
            Wenn Sie CBD Öl kaufen möchten, müssen Sie sich keinerlei Sorgen
            hinsichtlich einer Suchtgefahr machen, denn es gibt keine{" "}
            <strong>Indizien</strong> die dafür sprechen, dass Cannabidiol
            abhängig macht. Studien an Tieren haben gezeigt, dass, anders als
            bei THC,{" "}
            <a
              href="https://doi.org/10.1016/j.neuropharm.2006.11.005"
              target="_blank"
              rel="noreferrer noopener"
            >
              <strong>keine Toleranzen gegenüber CBD</strong>
            </a>{" "}
            aufgebaut werden.
          </p>
          <p>
            Ebenfalls in Tierversuchen nachgewiesen wurde, dass CBD keine
            Effekte wie THC oder andere Drogen hervorrufen, aus denen sich ein
            Missbrauchspotential ergeben könnte. Insbesondere wird{" "}
            <strong>
              das mesolimbisches System, das Belohnungszentrum im Gehirn,{" "}
              <a
                href="https://apps.who.int/iris/bitstream/handle/10665/279948/9789241210225-eng.pdf"
                target="_blank"
                rel="noreferrer noopener"
              >
                nicht aktiviert
              </a>
            </strong>
            . Auch unüblich hohe Dosen haben bei ansonsten gesunden Menschen
            keine negativen Auswirkungen hervorgerufen, wie man es bei THC
            erwarten würde.
          </p>
        </Block>
        <Block>
          <Row align="center" justify="center">
            <Col xs={12} lg={7}>
              <Heading2 id="high">Macht CBD Öl high?</Heading2>
              <p>
                Das Molekül der Hanfpflanze, welches beim Konsum
                &bdquo;high&ldquo; macht, ist{" "}
                <strong>Tetrahydrocannabinol</strong>, oder abgekürzt THC. Der
                Gesetzgeber regelt gemäß{" "}
                <a
                  href="https://dejure.org/gesetze/BtMG/1.html"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  §1 Abs. 1
                </a>{" "}
                i.V.m.{" "}
                <a
                  href="https://dejure.org/gesetze/BtMG/Anlage_I.html"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Anlage 1 Spalte 3 Nr. b BtMG (Betäubungsmittelgesetz)
                </a>
                , dass der THC Gehalt in CBD-haltigen Produkten{" "}
                <strong>weniger als 0,2 %</strong> betragen muss, um legal zu
                sein.
              </p>
              <p>
                Ein THC Gehalt von unter 0,2 % ist{" "}
                <strong>
                  viel zu wenig, um psychoaktive Effekte hervorzurufen
                </strong>
                . Cannabinoide wirken zwar über ihre psychoaktiven Inhaltsstoffe
                auf das zentrale Nervensystem, jedoch entfaltet sich die{" "}
                <a
                  href="https://www.bundesgesundheitsministerium.de/fileadmin/Dateien/5_Publikationen/Drogen_und_Sucht/Berichte/Broschuere/BMG_CaPris_A5_Info_web.pdf"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  stärkste Wirkung beim Cannabinoid THC
                </a>
                .
              </p>
              <p>
                <strong>
                  Der Konsum von CBD Öl mit einem THC-Wert unter 0,2 % macht
                  deshalb nicht &bdquo;high&ldquo;.
                </strong>
              </p>
              <p>
                Alle CBD Öle in unserem Test enthalten einen THC Gehalt unter
                0,2%, sofern ein Analysenzertifikat vorlag. Die Cannabis Öle in
                dem Test können daher legal gekauft werden. Zudem geben wir die{" "}
                <strong>
                  unabhängigen Kontrollstellen als Aussteller der
                  Analysenzertifikate
                </strong>{" "}
                in unserem CBD Öl Vergleich an. Sollte ein{" "}
                <a href="#vergleichstabelle">CBD Zertifikat</a> nicht öffentlich
                auf den Webseiten der CBD Öl Verkäufer zugänglich sein, kann das
                Hanf Öl des Anbieters keine hohe oder mittlere Empfehlung
                erhalten bzw. als{" "}
                <a href="#hohe-empfehlung">CBD Öl Testsieger</a> gelten.
              </p>
            </Col>
            <Col xs={12} sm={10} md={6} lg={5}>
              <DivHelper mt={20}>
                <StaticImage
                  src="../../assets/images/stock/dropper-mouth.jpg"
                  placeholder="blurred"
                  layout="fullWidth"
                  alt="CBD Öl Pipette tropft in Mund"
                />
              </DivHelper>
            </Col>
          </Row>
        </Block>
        <Block>
          <Heading2 id="geschmack">Wie schmeckt CBD Öl?</Heading2>
          <p>
            Der <strong>Geschmack von CBD Öl ist nicht immer gleich</strong>, da
            der Geschmack von CBD Öl je nach Anbieter variiert. Wir sehen es als{" "}
            <Link to="/ratgeber/produkt-ratgeber/">großen Vorteil</Link>, dass
            es viele CBD Öl Anbieter gibt, damit Sie aber nicht zahlreiche CBD
            Öle kaufen müssen, haben wir für Sie den Geschmack in unserem CBD Öl
            Test beschrieben. Einige Hanftropfen schmecken angenehm mild,
            hanfig, erdig, wurzelig, etwas bitter oder sehr stark bitter.{" "}
            <strong>
              Daher lohnt sich ein{" "}
              <a href="#vergleichstabelle">
                Vergleich der Geschmacksrichtungen
              </a>
            </strong>
            . Es gibt außerdem einige Hanföl CBDs, denen Fruchtaromen oder
            andere Öle beigesetzt wurden wie beispielsweise Schwarzkümmelöl,
            Zitrone oder Minze. Diese CBD Öle empfehlen wir ebenfalls, weil sie
            den Geschmack verbessern und die CBD Wirkung gleichbleibt. Schauen
            Sie hier nach, welche Geschmacksvariationen unsere{" "}
            <a href="#hohe-empfehlung">CBD Öl Testsieger</a> haben und lassen
            Sie sich selbst überzeugen.
          </p>
        </Block>
        <Block>
          <Row align="center" justify="center">
            <Col lg={6} order={{ lg: 2 }}>
              <Heading2 id="lagerung">
                Wie sollte man CBD Öl am besten lagern?
              </Heading2>
              <p>
                Cannabidiol ist lichtempfindlich und sollte deshalb vor allem{" "}
                <strong>vor Sonneneinstrahlung geschützt werden</strong>. Aus
                diesem Grunde sind alle CBD Öle in unserem Test{" "}
                <a href="#vergleichstabelle">in braunem Glas</a>. Wir empfehlen,
                keine CBD Öle im hellen Glas zu kaufen.
              </p>
              <p>
                Darüber hinaus sollte das Hanf Öl{" "}
                <strong>kühl und trocken</strong> gelagert werden. Am besten
                bewahrt man das Cannabis Öl nach dem Kauf{" "}
                <strong>im Kühlschrank</strong> auf. Dieser sollte aber nicht zu
                kalt eingestellt sein, da sich sonst Eiskristalle im Öl bilden
                können und die Hanföl Wirkung beeinflusst werden könnte.
              </p>
            </Col>
            <Col lg={6}>
              <DivHelper mtMobile={30}>
                <Link to="/">
                  <Logo src={logo} alt="CBD-Vergleiche.de" />
                </Link>
              </DivHelper>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <DateRight>
                <em>
                  Beitrag veröffentlicht am 24.02.2022. Zuletzt aktualisiert am{" "}
                  <strong>05.03.2022</strong>.
                </em>
              </DateRight>
            </Col>
          </Row>
        </Block>
      </Container>
    </Layout>
  )
}

export default CbdOel
